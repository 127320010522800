// specifying page title for every page the component is in (component has to be included in the file's jsx structure)
// SEO MUST!

// react + dependencies
import { useEffect } from 'react';

const PageTitle = ({ title }) => {
  const siteName = 'joint Cargo Bikes'; // specifying site name here

  useEffect(() => {
    document.title = `${title} - ${siteName}`; // specifying page title and site name with given separator in given form
    return () => {
      document.title = 'joint Cargo Bikes'; // Reset the document title when the component unmounts
    };
  }, [siteName, title]);

  return null;
};

export default PageTitle;
