// react + dependencies
import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
// app.js routing file
import App from "./App";
// global css file
import "./styles/index.css";

ReactDOM.createRoot(document.getElementById("root")).render(
  <Router>
    {/* also wrapping app.js with translations */}
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Router>,
);
