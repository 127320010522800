// react + dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import i18n from '../i18n';
// components
import PageTitle from '../components/PageTitle';

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
    <PageTitle title={t("Home.Title")} />
    <div className='w-full'>
      <div className='h-[70vh] w-full flex items-center justify-center'>
        <div className='h-full w-full relative bg-[url("../assets/products/h3d_joint.webp")] bg-center bg-no-repeat bg-cover'>
          <div className='h-full w-full absolute inset-0 bg-black opacity-75'>
          </div> 
          <div className='absolute h-full w-full flex items-center justify-center '>
            <div className='text-center text-white mt-[50px]'>
              <h1 className='text-3xl'>{t('Home.Title')}</h1>
              <hr className='my-2 w-1/4 m-auto border-white'/>
              <p className='text-lg'>{t('Home.Subtitle')}</p>
              <div className='flex gap-4 justify-center mt-4'>
                <NavLink to={`/${i18n.language}/products`}>
                  <button 
                    className="btn-primary" 
                    to={`/${i18n.language}/products`}>
                  {t('Products.Title')}
                  </button>
                </NavLink>
                <NavLink to={`/${i18n.language}/contact`}>
                  <button className="btn-secondary">
                  {t('Contact.Title')}
                  </button>
                </NavLink>
              </div>
            </div>
            </div>
        </div>
      </div>
      <div className='w-10/12 m-auto h-auto'>
        <img className='w-full h-full object-cover' src='https://am.apps.seaworks.io/media/cargobikesweb/pictures/b36c540c_Joint1.4626_5yAAWGm.jpg' alt='Product Image' />
        <div className='p-10'>
          {/* Content goes here */}
        </div>
      </div>

      <div className='w-10/12 m-auto'>
        <div className='p-10'>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum hic labore necessitatibus sed vero, doloribus autem iure, provident minus sit velit tempora illo accusantium dolores vel enim. Iure explicabo, ducimus ea quia ab dignissimos quaerat maxime saepe nihil libero hic, nesciunt obcaecati, delectus inventore et cupiditate voluptas. Dignissimos similique reprehenderit quia, officiis rem voluptatibus temporibus fuga, porro iste nostrum perspiciatis expedita impedit, fugiat a enim eaque nemo? Molestias deserunt dolores asperiores a magnam quidem nobis. Esse cupiditate debitis ipsum alias ipsam. Cumque, vitae! Saepe officiis minima quo mollitia nisi tempore iure, voluptatem fuga consequatur animi, reprehenderit suscipit ea repellendus nostrum?</p>
        </div>
      </div>

    </div>
    </>
  );
};

export default Home;
