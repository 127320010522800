// translations customization file

// react + dependencies
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Cookies from "js-cookie";
import XHR from "i18next-xhr-backend"; 
import axios from "axios"; 

const getLanguageFromCookie = () => {
  let language = Cookies.get("language");
  
  if (!language) {
    // Set the cookie to 'en'
    Cookies.set("language", "en", { expires: 365 }); // Set expiration if needed
    language = "en";
  }
  
  return language;
};

const loadTranslations = async (language) => {
  try {
    const response = await axios.get(`https://lm.apps.seaworks.io/cargobikesweb/translations/${language}/`);
    return response.data; // Assuming the response data contains your translations
  } catch (error) {
    console.error("Error loading translations:", error);
    return {}; // Return an empty object if translations cannot be loaded
  }
};

const language = getLanguageFromCookie();

// Initialize i18next with an empty resource bundle
i18n
  .use(initReactI18next)
  .init({
    lng: language,
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    ns: "translation",
    defaultNS: "translation",
    resources: {
      [language]: { translation: {} }, // Empty initial resource bundle
    },
  })
  .then(() => {
    // Load translations and update the i18next resources
    loadTranslations(language).then((translations) => {
      i18n.addResourceBundle(language, "translation", translations);
    });
  });

export default i18n;