// site-wide footer

// react + dependencies
import React from 'react'
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
// assets
import logo from '../assets/logo_dark.png';

const Navbar = () => {

    const { t } = useTranslation();

    return (
        <footer className='w-full border-t border-[color:var(--primary)]'>
            <div className='container m-auto pt-8 pb-4 px-2'>
                <div className='flex flex-col sm:flex-row gap-4 justify-between items-start '>

                    {/* 1/3 footer */}
                    <div className='w-1/4 lg:w-2/4'>
                        <div className='w-fit'>
                            <NavLink to={`/${i18n.language}/`}>
                                <img src={logo} width={100} alt="" />
                            </ NavLink>
                        </div>
                    </div>

                    <div className='w-full sm:w-1/4'>
                        <div>
                            <h3 className='font-semibold'>{t("Service.Title") || <Skeleton />}</h3>
                            <p><a className='my-1' href=''>{t("AuthorizedPartners.Title") || <Skeleton />}</a></p>
                            <p><a className='my-1' href=''>{t("Spareparts.Title") || <Skeleton />}</a></p>
                        </div>
                    </div>
                    <div className='w-full sm:w-1/4'>
                        <div>
                            <h3 className='font-semibold'>{t("Contact.Title") || <Skeleton />}</h3>
                            <p>{t("Contact.Description") || <Skeleton />}</p>
                        </div>
                    </div>
                    <div className='w-full sm:w-1/4'>
                        <div>
                            <h3 className='font-semibold'>{t("About.Title") || <Skeleton />}</h3>
                            <p className=''>{t("About.Description") || <Skeleton />}</p>
                        </div>
                    </div>

                    {/* 3/3 footer*/}

                </div>
            </div>

            {/* copyright */}
            <div className='mt-8 border-t bg-gray-50'>
                <div className='container py-6'>
                    {t("Footer.Copyright") || <Skeleton />}
                </div>
            </div>

        </footer>
    )
}

export default Navbar
