// products page

// react + dependencies
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Cookies from "js-cookie";
import i18n from '../i18n';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// components
import PageTitle from '../components/PageTitle';

const Products = () => {
  const { lang } = useParams();
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://am.apps.seaworks.io/cargobikesweb/bikes/');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    if (lang && lang !== i18n.language) {
      Cookies.set("language", lang, { expires: 365 });
      i18n.changeLanguage(lang);
      window.location.reload();
    }
    fetchProducts();
  }, [lang,i18n]); // Empty dependency array ensures the effect runs once after the initial render

    return (
        <>
        <PageTitle title={t("Products.Title")} />
        <div className='w-full'>
          <div className='h-[40vh] w-full flex items-center justify-center'>
            <div className='h-[40vh] w-full relative bg-[url("../assets/products/h3d_joint.webp")] bg-center bg-no-repeat bg-cover'>
              <div className='h-[40vh] w-full absolute inset-0 bg-black opacity-75'>
              </div> 
              <div className='absolute h-[40vh] w-full flex items-center justify-center '>
                <div className='text-center text-white'>
                  <h1 className='text-3xl'>{t('Products.Title')}</h1>
                  <hr className='my-4 w-2/4 m-auto border-white'/>
                  <p className='text-lg'>{t('Products.Subtitle')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full lg:w-10/12 m-auto py-6'>
            <div className='p-8 flex flex-col md:flex-row flex-wrap justify-start gap-10'>
              {products.map((product) => (
                <div key={product.sku} className='w-full lg:w-1/5 max-w-md'>
                <NavLink key={product.sku} to={`/${i18n.language}/products/${product.sku}`}>
                  <div className='shadow hover:shadow-lg rounded'>
                    <div className='w-fit border-b '>
                      <div className='h-48 w-fit m-auto flex items-center justify-center hover:opacity-80'>
                        <img src={product.product_tile_picture || <Skeleton/>} alt={product.name} />
                      </div>
                      <div>
                        <div className='flex flex-row w-fit flex-wrap justify-start items-center border-b border-t'>
                          {product.variants.map((variant, index) => (
                            <div className='hover:opacity-80 w-24' key={index}>
                              <img src={variant.picture} className='border' alt={variant.name} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='p-6'>
                      <h2 className='font-semibold mb-2'>{product.name || <Skeleton/>}</h2>
                      <p>{t(product.short_description) || <Skeleton/>}</p>
                      <div className='mt-4'>
                        <button className='btn-primary'>
                        {t('Product.Detail.Button')}
                        </button>
                      </div>
                    </div>
                    </div>
                </NavLink>
                </div>
              ))}
            </div>
          </div>      
        </div>                
    </>
    )
}

export default Products
