// this GlobalHeader is added to every page and adds necessary localization and routing info

// react + dependencies
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const GlobalHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    

    // Check if language is not set (undefined) and navigate to /en
    if (!i18n.language) {
      navigate('/en');
    }
  }, [navigate]); // Include navigate in the dependency array

  return null;
};

export default GlobalHeader;
