// contact page

// react + dependencies
import React from 'react'
import { useTranslation } from 'react-i18next';
// components
import PageTitle from '../components/PageTitle';

const Contact = () => {

    const { t } = useTranslation();

    return (
        <>
        <PageTitle title={t("Contact.Title")} />
        <div className='h-[40vh] w-full flex items-center justify-center'>
          <div className='h-[40vh] w-full relative bg-[url("../assets/products/h3d_joint.webp")] bg-center bg-no-repeat bg-cover'>
            <div className='h-[40vh] w-full absolute inset-0 bg-black opacity-75'>
            </div> 
            <div className='absolute h-[40vh] w-full flex items-center justify-center '>
              <div className='text-center text-white'>
                <h1 className='text-3xl'>{t('Contact.Title')}</h1>
                <hr className='my-4 w-2/4 m-auto border-white'/>
                <p className='text-lg'>{t('Contact.Subtitle')}</p>
              </div>
              </div>
          </div>
        </div>
        </>
    )
}

export default Contact
