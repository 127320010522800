// about page

// react + dependencies
import React from 'react'
import { useTranslation } from 'react-i18next';
// components
import PageTitle from '../components/PageTitle';
import Cookies from "js-cookie";
import i18n from '../i18n';
import { useParams } from 'react-router-dom';

const About = () => {

    const { t } = useTranslation();
    const { lang } = useParams();

    if (lang && lang !== i18n.language) {
      Cookies.set("language", lang, { expires: 365 });
      i18n.changeLanguage(lang);
      window.location.reload();
    }

    return (
        <>
        <PageTitle title={t("About.Title")} />
        <div className='h-[40vh] w-full flex items-center justify-center'>
        <div className='h-[40vh] w-full relative bg-[url("../assets/products/h3d_joint.webp")] bg-center bg-no-repeat bg-cover'>
        <div className='h-[40vh] w-full absolute inset-0 bg-black opacity-75'>
        </div> 
        <div className='absolute h-[40vh] w-full flex items-center justify-center '>
          <div className='text-center text-white'>
            <h1 className='text-3xl'>{t('About.Title')}</h1>
            <hr className='my-4 w-2/4 m-auto border-white'/>
            <p className='text-lg'>{t('About.Subtitle')}</p>
          </div>
          </div>
      </div>
    </div>
        </>
    )
}

export default About
