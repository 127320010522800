// site-wide header

// react + dependencies
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { NavLink, useLocation } from 'react-router-dom';
// components
import LanguageSwitcher from './LanguageSwitcher';
// assets
import logo from '../assets/logo.png';

const Navbar = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const isProductDetailPage = location.pathname.includes('/products/'); // different styling for products page (less opacity)
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <header className={`w-full h-fit py-4 md:py-0 md:h-[100px] border-b border-[color:var(--primary)] text-white absolute top-0 z-50 ${isProductDetailPage ? 'bg-gray-950/80 sm:bg-gray-950/10' : 'bg-gray-950/80 sm:bg-gray-950/50'}`}>
            <nav className='h-full w-10/12 m-auto'>
                <div className='h-full flex justify-between items-center'>
                    <div className='hover:scale-90 transition-all'>
                        <NavLink to={`/${i18n.language}/`}>
                            <img src={logo} className='w-20 sm:w-24' alt="" />
                        </ NavLink>
                    </div>

                    {/* Mobile Menu Button (Hamburger Icon) */}
                    <div className='md:hidden text-3xl'>
                        <button onClick={toggleMobileMenu}>
                            ☰
                        </button>
                    </div>

                    <div className='hidden md:flex flex-wrap gap-6 items-center py-4'>
                        <div className='flex gap-4 items-center'>
                            <div>
                                <NavLink 
                                    className={(navData) => (navData.isActive ? "active" : 'none')}
                                    to={`/${i18n.language}/`}>
                                        {t('Home.Title')}
                                </NavLink>
                            </div>
                            <div>
                                <NavLink 
                                    className={(navData) => (navData.isActive ? "active" : 'none')}
                                    to={`/${i18n.language}/products`}>
                                        {t('Products.Title')}
                                </NavLink>
                            </div>
                            <div>
                                <NavLink 
                                    className={(navData) => (navData.isActive ? "active" : 'none')}
                                    to={`/${i18n.language}/sustainability`}>
                                        {t('Sustainability.Title')}
                                </NavLink>
                            </div>
                            <div>
                                <NavLink 
                                    className={(navData) => (navData.isActive ? "active" : 'none')}
                                    to={`/${i18n.language}/about`}>
                                        {t('About.Title')}
                                </NavLink>
                            </div>
                            <div>
                                <NavLink 
                                    className={(navData) => (navData.isActive ? "active" : 'none')}
                                    to={`/${i18n.language}/contact`}>
                                        {t('Contact.Title')}
                                </NavLink>
                            </div>
                        </div>
                        <div className='hidden lg:block'>
                            <LanguageSwitcher />
                        </div>
                    </div>
                </div>
            </nav>
            
            {/* Mobile Menu */}
            {isMobileMenuOpen && (
                <div className='bg-gray-950/80 border-t border-[color:var(--primary)] mt-4'>
                    <div className='w-full '>
                        <div className='w-10/12 flex flex-col gap-4 p-4'>
                            <div>
                                <NavLink to={`/${i18n.language}/`} onClick={toggleMobileMenu}>
                                    {t('Home.Title')}
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to={`/${i18n.language}/products`} onClick={toggleMobileMenu}>
                                    {t('Products.Title')}
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to={`/${i18n.language}/about`} onClick={toggleMobileMenu}>
                                    {t('About.Title')}
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to={`/${i18n.language}/contact`} onClick={toggleMobileMenu}>
                                    {t('Contact.Title')}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                )}        
      </header>
    )
}

export default Navbar
