// react + dependencies
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { t } from 'i18next';
import Skeleton from 'react-loading-skeleton';
import i18n from '../i18n';
import Cookies from "js-cookie";
// components
import PageTitle from './PageTitle';

const ProductDetail = () => {
  const { sku, lang } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [accordionStates, setAccordionStates] = useState({
    description: false,
    technology: false,
    media: false,
    // need to add more attributes if in urge to add more accordion sections
  });

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get('https://am.apps.seaworks.io/cargobikesweb/bikes/');
        const filteredProduct = response.data.find((item) => item.sku.toLowerCase() === sku.toLowerCase());

        if (filteredProduct) {
          setProduct(filteredProduct);
          // Set the initial selected image to the main picture
          setSelectedImage(filteredProduct.product_main_picture);
        } else {
          // Handle case where the product with the given SKU was not found
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };
    if (lang && lang !== i18n.language) {
      Cookies.set("language", lang, { expires: 365 });
      i18n.changeLanguage(lang);
      window.location.reload();
    }
    fetchProduct();
  }, [sku, lang, i18n]);

  const handleImageClick = (imageURL) => {
    setSelectedImage(imageURL);
  };

  const toggleAccordion = (key) => {
    setAccordionStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  if (!product) {
    return <div className='mt-[120px] w-10/12 m-auto text-center py-6 p-8'>{t('Product.Feed.Loading')}</div>; // could add loading spinner instead of static text
  }

  return (
    <>
      <PageTitle title={product.name} />
      <div className='w-full' id='wrap'>
        {/* image background */}
        <div className='h-full lg:h-screen w-full relative bg-center bg-no-repeat bg-cover'>
          <img src={product.product_main_picture} className='h-auto md:h-full absolute left-0' alt={product.name} />
          {/* image background overlay - background transparency can be controlled by opacity- settings (100 for full black bg, 0 for no overlay) */}
          <div className='h-full w-full absolute inset-0 bg-black opacity-75'></div>
          <div className='w-full'>
            <div className='h-full flex flex-col lg:flex-row flex-wrap'>

              {/* left section */}
              <div className='h-full w-full lg:w-3/12 bg-gray-950/20 relative lg:absolute top-0 z-30 border-r border-[color:var(--primary)]'>
                <div className='text-white p-10 mt-[80px] sm:mt-[100px]'>
                  <div className='mb-6'>
                    <img src={product.vendor.logo} className='w-16 sm:w-24 rounded-none' alt={product.vendor.name} />
                  </div>
                  <h2 className='text-xl'>{product.name || <Skeleton />}</h2>
                  <p className='text-sm'>{product.sku || <Skeleton />}</p>
                  <hr className='my-4 border-white w-8/12 lg:w-1/3' />
                  <div className='line-clamp-6'>
                    <p className='text-sm'>{t(product.short_description) || <Skeleton />}</p>
                  </div>
                  <div className='my-4'>
                    {product.attributes.map((attribute, index) => (
                      <ul key={index} className='line-clamp-6'>
                        <li className='text-sm'>{t(attribute.key) || <Skeleton />}: {t(attribute.value) || <Skeleton />}</li>
                      </ul>
                    ))}
                  </div>
                  <div className='mt-4'>
                    {product.price ? (
                      <button className='btn-primary'>
                        <p>{t('Product.Detail.PriceFrom')} {product.price} €</p>
                      </button>
                    ) : (
                      <button className='btn-primary'>
                        <p>{t('Product.Detail.PriceOnRequest')}</p>
                      </button>
                    )}

                  </div>
                </div>
              </div>

              {/* right section */}
              <div className='w-full lg:w-9/12 bg-gray-950/40 relative lg:absolute top-0 right-0  z-30 h-full flex items-center justify-center'>
                <div className='w-10/12 m-auto text-white my-6 lg:my-0'>
                  <div className='flex flex-col lg:flex-row items-center gap-2'>
                    <div className='w-fit flex flex-row lg:flex-col justify-center items-center gap-2 order-2 lg:order1'>
                      {product.variants.map((variant, index) => (
                        <div className='w-fit' key={index}>
                          <img src={variant.picture} className='hover:opacity-80 w-32 cursor-pointer' alt={variant.name} onClick={() => handleImageClick(variant.picture)} />
                        </div>
                      ))}
                    </div>
                    <div className='w-full order-1 lg:order-2'>
                      <img src={selectedImage} className='rounded shadow' alt={product.name} />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* accordion */}
        <div className='w-full md:container m-auto'>
          <div className="w-full my-6 p-6 lg:p-0">
            <div className='w-full'>



              <div className={`w-full border shadow h-fit ${accordionStates.technology ? 'open' : ''}`} id='accordion-item'>
                <div className='p-6 border-b cursor-pointer' onClick={() => toggleAccordion('technology')}>
                  <div className='w-full flex justify-between'>
                    <div>
                      {t('Product.Detail.Accordion.Technology')}
                    </div>
                    <div>
                      {accordionStates.technology ? '-' : '+'}
                    </div>
                  </div>
                </div>
                {accordionStates.technology && (
                  <div className='bg-gray-100 p-6' id='tech-table'>
                    <table class="w-full table-auto">
                      <tbody>
                        <tr>
                          <td className='text-start w-6/12 bg-gray-600 text-white'>parameters - to translate</td>
                          <td className='text-start w-6/12 bg-gray-600 text-white'>values - to translate</td>
                        </tr>
                        <tr>
                          <td>{product.width ? `${t('Product.Detail.Width')}` : <Skeleton />}</td>
                          <td>{product.width}m</td>
                        </tr>
                        <tr>
                          <td>{product.height ? `${t('Product.Detail.Height')}` : <Skeleton />}</td>
                          <td>{product.height}m</td>
                        </tr>
                        <tr>
                          <td>{product.length ? `${t('Product.Detail.Length')}` : <Skeleton />}</td>
                          <td>{product.length}m</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              <div className='flex justify-between mt-6' id='description-media-wrap'>

                <div className={`w-6/12 mr-3 border shadow h-fit ${accordionStates.description ? 'open' : ''}`} id='accordion-item'>
                  <div className='p-6 border-b cursor-pointer' onClick={() => toggleAccordion('description')}>
                    {/* need to add toggleAccordions based on accordion values specified in accordionStates at the top of the code */}
                    <div className='w-full flex justify-between'>
                      <div>
                        {t('Product.Detail.Accordion.Description')}
                      </div>
                      <div>
                        {accordionStates.description ? '-' : '+'}
                      </div>
                    </div>
                  </div>
                  {accordionStates.description && (
                    <div className='bg-gray-100 p-6'>
                      {t(product.long_description) || <Skeleton />}
                    </div>
                  )}
                </div>

                <div className={`w-6/12 ml-3 border shadow h-fit ${accordionStates.media ? 'open' : ''}`} id='accordion-item'>
                  <div className='p-6 border-b cursor-pointer' onClick={() => toggleAccordion('media')}>
                    <div className='w-full flex justify-between'>
                      <div>
                        {t('Product.Detail.Accordion.Media')}
                      </div>
                      <div>
                        {accordionStates.media ? '-' : '+'}
                      </div>
                    </div>
                  </div>
                  {accordionStates.media && (
                    <div className='bg-gray-100'>
                      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4'>
                        {product.variants.map((variant, index) => (
                          <div className='w-full' key={index}>
                            <img src={variant.picture} alt={variant.name} />
                          </div>
                        ))}
                        {product.features.map((feature, featureIndex) => (
                          <div className='w-1/2 p-1'>
                            <img key={featureIndex} src={feature.picture} alt={feature.name} />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

          </div>

          {/* additional product info */}
          <div className='w-full my-12'>
            <div className='container m-auto p-8 lg:p-0'>
              <div className='w-10/12'>
                {product.features.map((feature, index) => (
                  <div className="flex flex-col md:flex-row" key={index}>
                    <div className='w-full lg:w-1/12 m-0 lg:m-6'>
                      <img src={feature.picture} className='w-fit' alt={feature.name} />
                    </div>
                    <div className='w-full lg:w-9/12 p-0 lg:p-6'>
                      <h3 className='font-semibold'>{t(feature.short_description)}</h3>
                      <p>{t(feature.long_description)}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  );
};

export default ProductDetail;
