// app.js routing file

// react + dependencies
import React, { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { Routes, Route, useNavigate } from "react-router-dom";
import i18n from "./i18n";
import Cookies from "js-cookie";
import axios from 'axios';
// components
import GlobalHeader from "./components/GlobalHeader";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ProductDetail from "./components/ProductDetail"; 
// pages
import Home from "./pages/Home";
import Products from "./pages/Products";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Sustainability from "./pages/Sustainability";

const App = () => {
  const navigate = useNavigate();

  // Read language preference from the cookie
  const languageFromCookie = Cookies.get("language");
  if (!languageFromCookie) {
    // Set the cookie to 'en'
    Cookies.set("language", "en", { expires: 365 }); // Set expiration if needed
    languageFromCookie = "en";
  }

  const fetchTranslations = async () => {
    return axios.get(`https://am.apps.seaworks.io/cargobikesweb/translations/${i18n.language}/`)
      .then(response => {
        i18n.addResourceBundle(i18n.language, 'translation', response.data, true, true);
        i18n.changeLanguage(i18n.language);
      })
      .catch(error => {
        console.error('Error fetching translations:', error);
      });
  };
  

  // Set language immediately after component mounts
  useEffect(() => {
    if (languageFromCookie) {
      
      i18n.changeLanguage(languageFromCookie);
      fetchTranslations();
      //navigate(`/${languageFromCookie}/`);
    }
  }, [languageFromCookie]);

  return (
    <I18nextProvider i18n={i18n}>
      <GlobalHeader />
      <Navbar />
      <Routes>
        <Route path="/:lang/" element={<Home />} />
        <Route path="/:lang/products" element={<Products />} />
        <Route path="/:lang/products/:sku" element={<ProductDetail />} />
        <Route path="/:lang/about" element={<About />} />
        <Route path="/:lang/contact" element={<Contact />} />
        <Route path="/:lang/sustainability" element={<Sustainability />} />
        <Route path="/*" element={<Home />} /> {/* redirect to homepage if url prefix doesn't match with routes specified above */}
      </Routes>
      <Footer />
    </I18nextProvider>
  );
};

export default App;
