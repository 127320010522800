// language switcher, allows users to switch frontend language based on localization keys
// customization file is located at "/src/i18n/index.js"

// react + dependencies
import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Flag from 'react-country-flag';
import Cookies from 'js-cookie';

const languages = [
  { code: 'en', label: 'English', country: 'GB' },
  { code: 'it', label: 'Italiano', country: 'IT' },
  { code: 'cz', label: 'Czech', country: 'CZ' }
];

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Initialize isDropdownOpen state variable

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    navigate(`/${lng}/`);
    setIsDropdownOpen(false);

    // Set language preference in a cookie
    Cookies.set('language', lng, { expires: 365 }); // Language preference stored for 365 days
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left my-4" ref={dropdownRef}>
      <button type="button" onClick={toggleDropdown} className="inline-flex items-center justify-center space-x-1 p-2">
        <Flag countryCode={languages.find(lang => lang.code === i18n.language)?.country} svg />
        {/* commented language name next to the currently active flag */}
        {/* <span>{languages.find(lang => lang.code === i18n.language)?.label}</span> */}
      </button>

      {isDropdownOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-32 rounded-md bg-white shadow">
          <div className="py-1 divide-y" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {languages.map(lang => (
              <button
                key={lang.code}
                onClick={() => changeLanguage(lang.code)}
                className="flex items-center gap-2 py-2 px-4 bg-transparent hover:bg-gray-100 w-full text-black"
                role="menuitem"
              >
                <Flag countryCode={lang.country} svg />
                <p>{lang.label}</p>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
